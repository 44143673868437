<template>
    <div class="p-relative pa-4">
        <v-btn
            depressed
            color="primary"
            @click="openContactDialog()"
            >
            Окно импорта номенклатуры
        </v-btn>

        <ImportNomenclatureDlg ref="ImportNomenclatureRef"/>
    </div>
</template>

<script>
import ImportNomenclatureDlg from '@/components/dialogs/ImportNomenclatureDlg'
export default {
    name: "Sandbox",
    components:{
        ImportNomenclatureDlg,
    },
    data: () => ({

    }),
    computed: {
        
    },
    methods: {
        async openContactDialog() {
            try {
                await this.$refs.ImportNomenclatureRef.open();
            } 
            catch (ex) {
                console.log(ex);
            }
        }
    }
}
</script>