<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label">{{ $t("Файл_импорта") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="9">
                                    <div class="icon-click-row-group">

                                        <div @click="$refs.atachmentsRef.click()">
                                            <input
                                                type="file"
                                                ref="atachmentsRef"
                                                @change="onFilesPicked"
                                                style="display: none"
                                                accept=".csv"
                                            />
                                            <v-icon small>
                                                fas fa-edit
                                            </v-icon>
                                        </div>
                                        
                                        <div class="cloud-file-list-box">
                                            <div class="cloud-items-container">

                                                <div v-if="!attachments || attachments.length == 0" class="cfl-placeholder">{{ $t('Нет_файлов') }}</div>

                                                <v-row 
                                                    v-else
                                                    dense
                                                >
                                                    <v-file-item
                                                        v-for="attachment in attachments" :key="attachment.name"
                                                        :name="attachment.name"
                                                        :size="attachment.size"
                                                        col-width="12"
                                                        :hide-load="true"
                                                        @on-delete-attachment="onDeleteAttachment(attachment)"
                                                        :is-read-only="true"
                                                    />
                                            </v-row>

                                            </div>
                                        </div>                
                                        

                                    </div>
                                </v-col>
                            </v-row>
                            <v-row 
                                v-if="preparedData.length > 0"
                                no-gutters>

                                <v-simple-table
                                    fixed-header
                                    class="customization-list-table fixed-header-scroll-table"                                    
                                >
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>Индекс</th>
                                                <th>Наименование номенклатуры дел</th>
                                                <th v-if="hasRetentionPeriod">Срок хранения</th>
                                                <th>Импорт</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="item in preparedData"
                                                :key="preparedData.indexOf(item)"
                                            >
                                                <td>{{ item.index }}</td>
                                                <td>
                                                    <div class="elipsis-text-wrap">
                                                        <div class="etw-text-container">
                                                            <div class="etw-text" v-tooltip.top-center="item[1]">
                                                                {{item.name}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td v-if="hasRetentionPeriod">{{ item.retentionPeriod }}</td>
                                                <td>{{ item.import }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-row>

                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="cyan" 
                        text 
                        depressed 
                        @click="save" 
                        :disabled="inProcess"
                        v-if="hasData && !isDone"
                        >
                        {{$t("Импортировать")}}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                        v-if="!isDone"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                        v-if="isDone"
                    >
                        {{$t("Закрыть")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { httpAPI } from "@/api/httpAPI";
import sys from '@/services/system';

export default {
    name: "ImportNomenclatureDlg",
    data () {
        return {
            title: i18n.t("Импорт_номенклатуры"),
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            importData: [],
            attachments: [],
            enterpriseId: null,  
            isDone: false,
            inProcess: false,
            isCancellation: false
        }
    },
    computed:{
        preparedData() {
            return this.importData.slice(1);
        },
        hasRetentionPeriod() {
            return this.importData[0].length > 2;
        },
        hasData(){
            return this.importData.length > 0; 
        }
    },
    methods: {
        async open(enterpriseId)
        {
            this.isCancellation = false;
            this.isDone = false;
            this.inProcess = false;
            this.enterpriseId = enterpriseId;
            this.importData = [];
            this.attachments = [];
            this.visible = true;  
            if (this.$refs.atachmentsRef)
                this.$refs.atachmentsRef.value = null;
        },
        async save() {
            this.inProcess = true;

            let timestampResponse = await httpAPI({
                    url: `/api/references/timestamp`,
                    method: 'GET'
            });            
            
            var currentDate = new Date(timestampResponse.data.payload);
            var fullYear =  currentDate.getFullYear();

            for(var i = 0; i < this.preparedData.length; i++)
            {
                if (this.isCancellation)
                    return;

                var item = this.preparedData[i];      

                var beginDate = new Date(`${fullYear}-01-01`);
                var endDate = new Date(`${fullYear}-12-31`);
                var nomenclatureObject = {
                    "__type": "Nomenclature:#Avrora.Objects.Modules.Docflow.DocflowObjects",
                    "DelRec": false,
                    "RecDate": `/Date(${Date.parse(currentDate)})/`,
                    "UserId": "00000000-0000-0000-0000-000000000000",
                    "is_new_record": true,
                    "id": sys.generateUUID(),
                    "ArchYear": null,
                    "ConnectionAlias": "Avrora3",
                    "OuterId": "00000000-0000-0000-0000-000000000000",
                    "ParentId": this.enterpriseId,
                    "BeginDate": `/Date(${Date.parse(beginDate)})/`,
                    "Code": item.index,
                    "Comment": null,
                    "Community": null,
                    "Counter": null,
                    "CounterId": "00000000-0000-0000-0000-000000000000",
                    "DocumentType": 0,
                    "EndDate":  `/Date(${Date.parse(endDate)})/`,
                    "Header": item.name,
                    "IsClosed": false,
                    "IsTransit": false,
                    "LifePeriod": null,
                    "LifeSpan": 0,
                    "RegistrationPlace": null
                };
                
                let response = await httpAPI({
                    url: `api/save?type=Chancellery.Nomenclature&entid=${this.enterpriseId}`,
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    data: { Content : JSON.stringify({ parent: nomenclatureObject, childrens: null }) },
                    skipErrorHandler: true,
                });

                console.log(response);

                if (response.data.payload.Result == "OK")
                {
                    item.import = "Ок";
                } 
                else
                {
                    item.import = "Ошибка";
                }
            }      

            this.isDone = !this.isCancellation; 
            this.inProcess = false;     
        },
        cancel() {
            if (this.inProcess)
                this.isCancellation = true
            else
                this.visible = false;
        },
        async onFilesPicked(e) {
            
            this.isCancellation = false;
            this.isDone = false;
            this.inProcess = false;            
            this.importData = [];
            this.attachments = [];

            this.attachments = Array.from(e.target.files);    

            if (this.attachments.length == 0)        
                return;

            const importFile = e.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                const text = e.target.result;

                this.importData = text
                    .split('\n')
                    .map(x => x.split(';').map(i => i.replaceAll('"', '').trim()))
                    .filter(i => i[0].length > 0)
                    .map(x => x.length > 2 
                        ? ({ "isSelected": false, "index": x[0], "name": x[1], "retentionPeriod": x[2] , "import": "" }) 
                        : ({ "isSelected": false, "index": x[0], "name": x[1], "import": "" }));                
            };

            reader.readAsText(importFile);
        },
        onDeleteAttachment() {
            if (this.inProcess)
                return;

            this.isCancellation = false;
            this.isDone = false;
            this.importData = [];
            this.attachments = [];
            this.visible = true;

            if (this.$refs.atachmentsRef)
                this.$refs.atachmentsRef.value = null;
        },
    }
}
</script>